<template>
    <div class="theme-master">
        <smu-toast :toasts="toasts" />
        <smu-site title="Master">
            <template #header>
                <smu-header
                    :title="appName"
                    :subtitle="$t('frontend.master_administration')"
                >
                    <template #center>
                        <page-header-edit-state v-if="hasChanges" />
                        <breadcrumbs v-if="!hasChanges" />
                    </template>
                    <template #right-side>
                        <div v-if="!hasChanges" class="relative">
                            <language-dropdown />
                        </div>
                        <div v-if="user && !hasChanges" id="userMenu">
                            <smu-user-menu :user="user">
                                <smu-user-menu-item icon="fa-pencil">
                                    {{ $t("helpers.buttons.change_password") }}
                                </smu-user-menu-item>
                                <smu-user-menu-item
                                    icon="fa-arrow-right-from-bracket"
                                    @click="logout"
                                >
                                    {{ $t("helpers.buttons.logout") }}
                                </smu-user-menu-item>
                            </smu-user-menu>
                        </div>
                    </template>
                    <span>{{ route.meta.title?.() }}</span>
                </smu-header>
            </template>
            <template v-if="user" #menue>
                <div class="flex h-full flex-col justify-between">
                    <div><page-main-menu /></div>
                    <div><page-default-footer /></div>
                </div>
            </template>
            <slot />
        </smu-site>
    </div>
</template>

<script setup lang="ts">
const { useToast } = await import("@smakecloud/smake-ui");

const { data } = storeToRefs(useUserStore());

const user = data;

const appName = computed(() => {
    return data.value?.master.name ?? "";
});

const { logout } = useTokenStore();

const { hasChanges } = useUnsavedChangesHeader();

const { toasts } = useToast();

const route = useRoute();

useHead({
    titleTemplate: (title) =>
        title
            ? `${title} - Smake Master Administration`
            : "Smake Master Administration",
    meta: [{ property: "og:title", content: "Smake Master Adminitration" }],
});
</script>
